body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.test-answer button.true {
    border: 1px solid #04aa6d;
}
.test-answer button.false {
    border: 1px solid #ff0000;
}

.container {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 15px;
    display: flex;
    justify-content: space-between;
}
.left {
    width: 30%;
}
.left-card {
    width: 100%;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 15px #333;
    text-align: center;
}
.left-card img {
    width: 100%;
    border-radius: 15px 15px 0 0;
}
.left-card-title {
    color: #333;
    margin: 10px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}
.left-card h2 {
    text-align: center;
}
.left-card-job {
    color: #333;
    font-style: italic;
    margin: 10px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}
.social-btns {
    padding: 10px 10px;
}
.social-btns a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #04aa6d;
    border-radius: 15px;
    text-decoration: none;
    margin: 10px 0;
    padding: 12px 24px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
    background-color: #04aa6d;
    transition: all 0.3s ease-in-out;
}
.social-btns a:hover{
    background-color: #fff;
    color: #04aa6d;
}
.info-ilova{
    padding: 20px 0;
}
.info-ilova h3{
    border-bottom: 1px solid #333;
    margin: 10px 0;
}
.info-ilova h1{
    padding: 10px 0;
    text-align: center;
}



.right {
    width: 70%;
}
.dictionary-help {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px #333;
}
.dictionary {
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 0 10px #000;
    border-radius: 10px;
}
.dictionary-header {
    height: 80px;
    text-align: center;
    line-height: 80px;
    margin-top: 20px;
    background-color: #04aa6d;
    color: #fff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #000;
}
.dictionary-body {
    padding: 20px 10px;
}
.table-dictionary table {
    border-collapse: collapse;
    width: 100%;
}
.table-dictionary table th,
.table-dictionary table td {
    border: 1px solid #ddd;
    padding: 8px;
}
.table-dictionary table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
}
.table-dictionary table tr:hover {
    background-color: #ddd;
    cursor: pointer;
}
.table-dictionary tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* SELECT */
.select {
    width: 100%;
    margin: 0 auto;
}
.select-title {
    text-align: center;
}
.select-number {
    display: block;
    width: 50%;
    margin: 5px auto;
    padding: 5px 10px;
}

/* LEARN1 */
.learn-one {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.learn-one__select {
    width: 90%;
    display: block;
    margin: 5px auto;
    padding: 5px 10px;
}

/* TEST */
.test {
    width: 80%;
    height: 50%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px #000;
    border-radius: 15px;
    padding: 20px;
}
.test-word {
    text-align: center;
}
.test-answer {
}
.test-answer .btn-answer {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 12px 0;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
    font-weight: bold;
    font-size: 24px;
}
.test-answer .btn-answer:hover {
    background-color: #ddd;
}

/* TEST DICTIONARY */
.result {
    height: 50%;
}
.result h3 {
    text-align: center;
    margin: 10px 0;
}
.result .btn-result,
.btn-write {
    padding: 12px 24px;
    background-color: #04aa6d;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #04aa6d;
    outline: none;
    width: 100%;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}
.result .btn-result:hover {
    background-color: #fff;
    color: #04aa6d;
    border: 2px solid #04aa6d;
}

/* WRITE DICTIONARY  */
.write-card {
    width: 70%;
    height: 30%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 10px #000;
    border-radius: 15px;
    padding: 20px;
}
.write-wrapper {
    text-align: center;
}
.input-write {
    width: 40%;
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    margin: 15px 0;
    padding: 8px 15px;
    font-size: 20px;
}
.result h1 {
    text-align: center;
}
@media screen and (max-width: 1200px) {
    .container{
        width: 80%;
        margin: 0 auto;
        flex-direction: column;
    }
    .left{
        width: 50%;
        margin: 0 auto;
    }
    .right{
        margin: 0 auto;
    }
    .right{
        width: 97%;
    }
}
@media screen and (max-width: 750px){
    .container{
        width: 90%;
        margin: 0 auto;
    }
    .left{
        width: 80%;
        margin: 0 auto;
    }
    .left-card-title{
        font-size: 16px;
    }
    .left-card-job{
        font-size: 12px;
    }
    .left-card h4{
        font-size: 14px;
    }
    .right{
        width: 97%;
    }
}
@media screen and (max-width: 650px){
    .left{
        width: 70%;
        margin: 0 auto;
    }
    .right{
        width: 97%;
    }
}
@media screen and (max-width: 450px){
    .left{
        width: 80%;
        margin: 0 auto;
    }
    .right{
        width: 97%;
    }
    .title{
        font-size: 16px;
    }
    table th, table td{
        font-size: 12px;
    }
}